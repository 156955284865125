@import "../../../../scss/imports.scss";

.event-details {
  width: calc(100vw - 48px) !important;
  margin: 50px auto 0px auto;
  .event-info-video {
    display: flex;
    flex-flow: column-reverse wrap;
  }
  .desktop-event-video-title {
    display: none;
  }
  .event-banner {
    position: relative;
    height: 0px;
    padding-bottom: 35%;
    margin-bottom: 30px;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    .event-poster {
      filter: blur(10px);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .shade {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.85);
      z-index: 1;
    }
    .event-banner-container {
      position: absolute;
      padding: 20px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      h1 {
        font-size: 14px;
        text-align: center;
        font-family: MuliSemiBold;
        position: relative;
        z-index: 2;
      }
      button {
        position: relative;
        z-index: 2;
        padding: 5px 40px;
      }
    }
  }
  .event-info {
    margin-bottom: 20px;
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .info-body {
      min-width: 300px;
      max-width: 900px;
    }
    .actions {
      width: 30%;
      min-width: 300px;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      & > button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30%;
        max-width: 180px;
        height: 40px;
        padding: 0px 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #333;
        border: 0px;
        color: white;
        font-size: 18px;
        font-family: Muli;
        max-width: 180px;
        &.btn-radivision {
          margin: 0px 20px 0px 0px !important;
        }
      }
      button.grey-out {
        background-image: none;
        background-color: #666666;
        color: white;
      }
    }
    .date {
      color: $radivision-blue;
      font-family: MuliExtraBold;
      font-size: 18px;
      margin: 0px 0px 15px 0px;
    }
    .title {
      color: white;
      font-family: MuliExtraBold;
      font-size: 20px;
      margin: 0px 0px 15px 0px;
    }
    .description {
      color: #666;
      font-family: Muli;
      text-align: justify;
      font-size: 16px;
    }
  }
  .live-video,
  .live-now {
    width: 100%;
    max-width: 100%;
    margin-top: 0px !important;
    box-sizing: border-box;
    background-color: transparent !important;
  }
  .video-container {
    background-color: black;
    border-radius: 5px;
  }
  .comments-container {
    margin-left: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  .companies-sponsors {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0px 0px 70px 0px;
    h4 {
      color: white;
      font-family: MuliExtraBold;
      font-size: 20px;
      margin: 40px 0px 20px 0px;
    }
    .companies {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      .company {
        width: 60px;
        height: 60px;
        margin: 0px 8px;
        display: flex;
        justify-content: start;
        align-items: center;
        a {
          flex: 1;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #aaa;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          transition: transform 0.14s ease-out;
          &:hover,
          &.selected {
            transform: scale(1.3);
          }
        }
      }
    }
    .companies-list {
      width: 70%;
      min-width: 350px;
      justify-content: flex-start;
      h4 {
        color: white;
      }
    }
    .sponsors-list {
      width: 30%;
      min-width: 300px;
      justify-content: flex-start;
      h4 {
        color: #888;
      }
    }
  }
  .event-company-info {
    display: flex;
    flex-flow: row wrap;
    .section {
      width: 50%;
      min-width: 350px;
      margin-bottom: 30px;
    }
    .section.title {
      sup {
        color: black;
        font-family: Muli;
        font-size: 14px;
        color: #888;
      }
      h2 {
        margin: 0px 0px 30px 0px;
        a {
          color: white;
          font-family: MuliBold;
          font-size: 24px;
          text-decoration: none;
        }
      }
      .actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        button.btn-radivision,
        button.invest-btn {
          width: 35%;
          border: 0px;
          margin-right: 20px;
          height: 30px;
          background-color: white;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          font-family: Muli;
          font-size: 16px;
          z-index: 10;
        }
        button.btn-radivision {
          min-height: 36px;
          min-width: 160px;
          border-radius: 20px;
          margin: 0px 10px 0px 0px;
          padding: 10px 15px;
          color: white;
          font-size: 16px;
          svg {
            width: 16px;
            margin-left: 10px;

            fill: white;
          }
        }
        .is-sent {
          button.btn-radivision {
            background-color: #666;
            color: #aaa;
            background-image: none;
          }
        }
        button.invest-btn {
          position: relative;
          .invest-menu {
            display: none;
            position: absolute;
            border-radius: 0px 0px 20px 20px;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px 15px;
            background-color: white;
            top: 29px;
            width: auto;
            left: 0px;
            p {
              text-align: left;
              font-size: 16px;
              font-family: MuliSemiBold;
            }
            ul {
              margin: 0px;
              padding: 0px;
              li {
                list-style: none;
                cursor: pointer;
                margin: 6px 0px;
                padding: 0px;
              }
            }
            label {
              font-size: 14px;
            }
            .checkbox-control {
              align-items: center;
              label {
                padding: 0px;
                margin-bottom: 0px;
                font-size: 16px;
              }
            }
            .checkbox-sign {
              width: 18px;
              height: 18px;
              border: 1px solid #888;
              background-color: white;
            }
            textarea {
              max-width: 100%;
              width: 100%;
            }
            .btn-radivision {
              position: relative;
              margin: 10px auto;
              font-size: 16px;
              color: white;
              padding: 4px 30px;
              height: auto;
              width: auto;
            }
          }
          &:focus,
          &:hover {
            border-radius: 20px 20px 0px 0px;
            .invest-menu {
              display: flex;
            }
            &.hide-it {
              border-radius: 20px;
            }
          }
          &.hide-it {
            &:focus,
            &:hover {
              .invest-menu {
                display: none;
              }
            }
          }
        }
      }
    }
    .section.founders {
      h4 {
        font-size: 18px;
      }
      .profile-picture-name {
        margin-top: 20px;
        .profile-picture {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  .company-infos {
    .company-info {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      font-family: "Muli";
      color: #888;
      margin-bottom: 24px;
      display: flex;
      flex-flow: row nowrap;

      h4 {
        font-size: 14px;
        font-family: "Muli";
        color: rgba(255, 255, 255, 0.6);
        width: 140px;
        max-width: 140px;
        margin: 0px 20px 0px 0px;
        padding: 0px;
        flex-shrink: 0;
      }
      p {
        margin: 0px 0px 3px 0px;
        padding: 0px;
      }
    }
  }
  .about-profile {
    h3.section-title {
      font-size: 20px;
      color: white;
    }
    .about-description {
      margin: 30px 0px;
      padding-right: 40px;
      color: #888;
    }
  }
  .sections-row {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    & > div {
      width: 50%;
      box-sizing: border-box;
      min-width: 350px;
      flex-grow: 1;
    }
    & > div.investment {
      .pitch-video-preview.centered-pitch .pitch-card {
        justify-content: flex-start;
        width: 100%;
      }
      .pitch-video-preview.centered-pitch .pitch-card .pitch-card-video {
        width: 100%;
      }
      .pitch-tool-bar,
      .opportunities-container {
        width: 100%;
        .opportunity {
          border: 0px;
          padding: 0px 0px;
        }
        .btn-radivision {
          margin-top: 0px;
        }
      }
      .opportunity-link {
        display: none;
      }
      .opportunity-body {
        h2 {
          font-size: 20px;
          color: white;
          font-family: Muli;
          margin: 20px 0px 10px 0px;
        }
        p {
          color: #888;
        }
      }
    }
  }
  @media (min-width: 600px) {
    margin: 140px auto 0px auto;
    .event-info {
      margin-bottom: 40px;
    }
    .event-info-video {
      display: flex;
      flex-flow: row wrap;
    }
    .mobile-event-video-title {
      display: none !important;
    }
    .desktop-event-video-title {
      display: flex;
    }
    .companies-sponsors {
      flex-direction: row;
      justify-content: space-between;
      h4 {
        margin: 40px 0px 40px 0px;
      }
      .companies {
        .company {
          width: 50px;
          height: 50px;
          a {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .live-now {
      height: auto!important;
      overflow: initial;
      &.chat-hidden {
        margin-bottom: 40px;
      }
    }
    .live-now.chat-hidden {
      height: 100px;
    }
    .open-chatbox {
      bottom: -50px!important;
      top: auto!important;
    }
    .hero-container.live-video {
      height: auto!important;
    }
    .carousel-item.active {
      height: auto!important;
    }
  }
  @media (min-width: 1000px) {
  }
  @media (min-width: 1200px) {
    width: calc(100vw - 140px) !important;
    .event-banner {
      margin-bottom: 0px;
      .event-banner-container {
        h1 {
          font-size: 20px;
          margin: 0px 20%;
        }
        button {
          position: relative;
          z-index: 2;
          padding: 7px 70px;
        }
      }
    }
    .event-info {
      .date {
        font-size: 22px;
      }
      .title {
        font-size: 24px;
      }
      .description {
        text-align: left;
        font-size: 18px;
      }
      .event-body {
        width: 70%;
        max-width: 400px;
      }
      .actions {
        button {
          width: 50%;
          padding: 0px 20px;
          font-size: 16px;
          height: 36px;
        }
      }
    }
    .companies-sponsors {
      h4 {
        font-size: 16px;
        margin: 40px 0px 20px 0px;
      }
      .companies {
        .company {
          width: 50px;
          height: 50px;
          margin: 0px 8px;
          a {
            width: 50px;
            height: 50px;
          }
        }
      }
      .sponsors-list {
        h4 {
          color: #555;
        }
      }
    }
    .event-company-info {
      margin-bottom: 70px;
      .section {
        width: 50%;
      }
      .section.title {
        sup {
          font-size: 14px;
        }
        h2 {
          a {
            font-size: 32px;
          }
        }
        .actions {
          button.intro-btn,
          button.invest-btn {
            width: 35%;
            margin-right: 20px;
            height: 36px;
            box-sizing: border-box;
            background-color: white;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            font-family: MuliBold;
            font-size: 16px;
          }
          button.intro-btn {
          }
          button.invest-btn {
          }
        }
      }
      .section.founders {
        h4 {
          font-size: 20px;
          font-family: MuliExtraBold;
        }
      }
    }
    .about-profile {
      h3.section-title {
        font-size: 20px;
        font-family: MuliExtraBold;
      }
    }
    .sections-row {
      & > div {
      }
      & > div.investment {
        .pitch-video-preview.centered-pitch .pitch-card {
        }
        .pitch-video-preview.centered-pitch .pitch-card .pitch-card-video {
        }
        .pitch-tool-bar,
        .opportunities-container {
          .opportunity {
          }
          .btn-radivision {
          }
        }
        .opportunity-link {
        }
        .opportunity-body {
          h2 {
            font-size: 20px;
            color: white;
            font-family: MuliExtraBold;
          }
        }
      }
    }
  }
  @media (min-width: 1900px) {
    .event-banner {
      .event-banner-container {
        h1 {
          font-size: 31px;
          margin: 0px 20%;
        }
        .date {
          font-size: 24px;
        }
      }
    }
    .event-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .info-body {
        width: 70%;
        max-width: 900px;
        .date {
          font-size: 26px;
        }
        .title {
          font-size: 28px;
        }
      }
      .actions {
        button.intro-btn,
        button.invest-btn {
          padding: 0px 10px;
          font-size: 18px;
        }
      }
    }
    .companies-sponsors {
      display: flex;
      justify-content: space-between;
      h4 {
        font-size: 25px;
      }
      .companies {
        .company {
          width: 75px;
          height: 75px;
          a {
            width: 75px;
            height: 75px;
          }
        }
      }
      .sponsors-list {
        max-width: 386px;
      }
    }
    .event-company-info {
      .section.title {
        sup {
          font-size: 14px;
        }
        h2 {
          a {
            font-size: 40px;
          }
        }
      }
      .actions {
        button.intro-btn,
        button.invest-btn {
          width: 25%;
          max-width: 180px;
          font-size: 20px;
          height: 50px;
          border-radius: 25px;
        }
      }
      .section.founders {
        h4 {
          font-size: 25px;
          font-family: MuliExtraBold;
        }
      }
    }
    .about-profile {
      h3.section-title {
        font-size: 30px;
        font-family: MuliExtraBold;
      }
      .about-description {
        font-family: Muli;
        font-weight: 100;
        font-size: 25px;
        line-height: 40px;
        color: #666;
        margin-bottom: 70px;
      }
      .company-info {
        p,
        h4 {
          font-family: Muli;
          font-weight: 100;
          font-size: 25px;
          line-height: 30px;
          color: #666;
        }
        h4 {
          width: 240px;
          max-width: unset !important;
          white-space: nowrap;
        }
      }
    }
    .sections-row {
      & > div {
      }
      & > div.investment {
        .pitch-video-preview.centered-pitch .pitch-card {
        }
        .pitch-video-preview.centered-pitch .pitch-card .pitch-card-video {
        }
        .pitch-tool-bar,
        .opportunities-container {
          .opportunity {
          }
          .btn-radivision {
          }
        }
        .opportunity-link {
        }
        .opportunity-body {
          h2 {
            font-size: 30px;
            color: white;
            font-family: MuliExtraBold;
          }
          p {
            font-family: Muli;
            font-weight: 100;
            font-size: 25px;
            line-height: 40px;
            color: #666;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
