@import "../../../../../scss/common.scss";
.opportunities-container {
  flex-wrap: wrap;
  a.action-button,
  a.btn-radivision {
    text-decoration: none;
    display: flex;
    background-color: #282828;
    background-image: none;
    width: 100%;
    position: relative;
    margin: 10px auto;
    align-items: center;
    padding: 15px 50px;
    border-radius: 35px;
    .button-text {
      color: white;
      font-family: MuliBold;
      font-size: 16px;
      min-width: 200px;
    }
    span {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: rgba(255, 255, 255, 0.1);
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 13px;
      margin-right: 13px;
      svg {
        max-width: 32px;
        width: 32px;
        fill: $radivision-blue;
      }
    }
  }
}

@media (min-width: 800px) {
  .opportunities-container {
    a.action-button,
    a.btn-radivision {
      max-width: calc(60% - 80px);
      .button-test {
        font-size: 20px;
      }
    }
  }
}
