@import "../../../../scss/imports.scss";

.events-panel {
  width: 100vw;
  margin-bottom: 40px;
  .horizontal-scroller-container {
    .tab-scroll-btn {
      position: absolute;
      width: 30px;
      height: 100%;
      top: 0px;
      bottom: 0px;
      z-index: 10;
      display: flex;
      justify-content: center;
      transform: none;
      align-items: center;
      svg {
        fill: #aaaaaa;
        width: 30px;
        height: 30px;
      }
    }
    .tab-next {
      right: 0px;
      background: linear-gradient(90deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 1) 75%);
    }
    .tab-prev {
      left: 0px;
      background: linear-gradient(90deg, rgba(22, 22, 22, 1) 22%, rgba(22, 22, 22, 0) 100%);
    }
  }
  .card-container {
    border: 0px solid;
    display: inline-block;
    margin: 0 15px;
    width: 300px;
    flex-shrink: 0;
    background-color: transparent;
    user-select: none;
    &:first-child {
      margin-left:-15px;
    }
  }
  .event-carousel-arrow {
    border: 0px;
    width: 50px;
    svg {
      fill: white;
      opacity: 0.8;
    }
    &:first-child {
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 22%, rgba(0, 0, 0, 0) 100%);
      order: 1;
    }
    &:last-child {
      background: rgb(0, 0, 0);
      background: linear-gradient(270deg, rgba(0, 0, 0, 1) 22%, rgba(0, 0, 0, 0) 100%);
      order: 3;
    }
  }
  .event-card {
    background-color: #212121;
    display: inline-block;
    flex: 1;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    align-items: stretch;
    .event-card-image {
      height: 0px;
      width: 100%;
      padding-bottom: 55%;
      background-color: #555;
      background-position: center center;
      background-size: cover;
    }
    .event-card-body {
      display: flex;
      flex-flow: column;
      padding: 20px;
      flex-grow: 1;
      h4.date {
        a {
          color: $radivision-blue;
          font-size: 15px;
          font-family: MuliSemiBold;
          text-decoration: none;
        }
      }
      p.title {
        color: white;
        font-size: 18px;
        font-family: MuliSemiBold;
        flex-grow: 1;
      }
      .rsvp {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 45%;
          background-color: $radivision-blue;
          color: white;
          font-size: 16px;
          font-family: MuliSemiBold;
          border: 0px;
          border-radius: 18px;
          height: 36px;
          margin: 10px auto;
          position: relative;
          align-self: center;
          padding: 2px 0px;
        }
        .grey-out {
          background-image: none;
          background-color: #666666;
        }
      }
    }
  }
  .panel-title {
    padding: 10px 24px 50px 24px;
  }
  @media (min-width: 320px) {
    .react-horizontal-scrolling-menu--scroll-container {
      height: 430px;
    }
    .event-card {
      .event-card-image {
      }
      .event-card-body {
        h4.date {
          font-size: 15px;
        }
        p.title {
          font-size: 20px;
        }
      }
    }
  }
  @media (min-width: 800px) {
  }
  @media (min-width: 1000px) {
  }
  @media (min-width: 1200px) {
    margin-bottom: 70px;
    .react-horizontal-scrolling-menu--scroll-container {
      height: 380px;
    }
    .horizontal-scroller-container {
      .tab-scroll-btn {
        width: 60px;
        svg {
          width: 60px;
          height: 60px;
        }
      }
    }
    .panel-title {
      padding: 10px 70px 50px 70px;
    }
    .events-carousel {
      margin-left: 70px;
    }
    .event-card {
      .event-card-image {
      }
      .event-card-body {
        h4.date {
          a {
            color: $radivision-blue;
            font-size: 15px;
            font-family: MuliSemiBold;
            text-decoration: none;
          }
        }
        p.title {
          font-size: 16px;
          color: #ccc;
          font-family: Muli;
        }
      }
    }
    .card-container {
      &:first-child {
        margin-left: 30px;
      }
    }
  }
  @media (min-width: 1900px) {
    .react-horizontal-scrolling-menu--scroll-container {
      height: 450px;
    }
    .panel-title {
      padding: 10px 70px 50px 70px;
    }
    .events-carousel {
    }
    .card-container {
      width: 380px;
    }
    .event-card {
      border-radius: 8px;
      .event-card-image {
      }
      .event-card-body {
        h4.date {
          font-size: 15px;
        }
        p.title {
          font-size: 20px;
        }
      }
    }
  }
}
.success-modal {
  .modal-header {
    p {
      text-align: center;
      flex-grow: 1;
    }
  }
  .modal-body {
    padding-top: 10px !important;
  }
  .modal-success-inner {
    h1 {
      font-size: 20px;
      text-align: center;
    }
    h2 {
      font-size: 19px;
      text-align: center;
    }
    h3 {
      font-size: 18px;
      text-align: center;
    }
  }
}
